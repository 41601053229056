<template>
  <div class="container-box">
    <div class="main-box">
      <!-- 面包屑 -->
      <div class="crumbs">
        <a>个人中心</a>
        <span class="icon">></span>
        <a @click="$router.go(-1)">课程学习</a>
        <span class="icon">></span>
        <span class="text">小节自测</span>
      </div>

      <!-- 答题 -->
      <div class="answer-box" :class="showAnswer?'answer':''">
        <div class="title">{{$route.query.courseName}}</div>
        <div class="selectionTitle"><img src="@/assets/image/image67.png" alt="">{{$route.query.sectionName}}</div>
        <!-- 问题 -->
        <div class="questions" v-for="(item,index) in list" :key="'libraryId'+item.libraryId">
          <!-- 题目 -->
          <div class="subject">
            <div style="height: 30px;display: flex;align-items: center;">
              <span class="type">{{item.type==1?'单选题':item.type==2?'多选题':'判断题'}}</span>
            </div>
            <span class="txt">{{index+1}}、{{item.title}}</span>
          </div>
          <!-- 选项 -->
          <template v-for="(items,indexs) in item.optionList">
            <a-radio v-if="item.type==1||item.type==3" :value="items.optionId" :checked="items.value" @change="changeOption(index,items,$event)" class="option" :class="funIs(items)" :disabled="showAnswer?true:false" :key="indexs">
              <div style="display:inline-block;">
                <div style="display: flex;flex-direction: row;flex: 1;">
                  <span class="icon">{{ABC[indexs]}}</span>
                  <span>、</span>
                  <span class="txt">{{items.name}}</span>
                </div>
              </div>
            </a-radio>
            <a-checkbox v-else :checked="items.value" :value="items.optionId" @change="changeOption(index,items,$event)" class="option" :class="funIs(items)" :disabled="showAnswer?true:false" :key="indexs">
              <div style="display:inline-block;">
                <div style="display: flex;flex-direction: row;flex: 1;">
                  <span class="icon">{{ABC[indexs]}}</span>
                  <span>、</span>
                  <span class="txt">{{items.name}}</span>
                </div>
              </div>
            </a-checkbox>
          </template>
          <div class="answer-analysis" v-if="showAnswer">
            <span>答题结果：<i :class="funAnalysis(item.optionList) ? 'iTrue' : 'iFalse'">{{funAnalysis(item.optionList) ? '正确' : '错误'}}</i></span>
            <p class="txt" v-show="item.answer"><i>题目解析</i> {{item.answer}}</p>
          </div>
        </div>
        <!-- 交卷 -->
        <div class="operation">
          <template v-if="showAnswer">
            <a-button size="large" style="margin-right:100px;" @click="showAnswer=false;getExemList()">重新答题</a-button>
            
						<a-button class="all-btn-small" type="primary" size="large" v-if="result.isPass == 1" @click="goStudy()">继续学习</a-button>
						<a-button class="all-btn-small" type="primary" size="large" v-else @click="onReturn()">重新学习</a-button>
          </template>
          <template v-else>
            <a-button style="margin-right:100px;" size="large" @click="onReturn()">取消</a-button>
            <a-button class="all-btn-small" :loading="loading" type="primary" size="large" @click="onSave()">提交</a-button>
          </template>
        </div>
      </div>
    </div>

    <a-modal v-model="submitPopup" :closable="false" :footer="null" width="480px" :keyboard="false" :maskClosable="false">
      <img class="popup-img" v-if="result.isPass == 1" src="@/assets/image/image66.png" alt="">
      <div class="popup-content">
        <div class="titleh4">小节自测{{result.isPass != 1?'未通过':'通过'}}</div>
        <div class="tips">
          <template v-if="result.isPass != 1">本次测试正确率需达到<span style="color:#15B7DD;">{{result.correctRate}}%</span>可解锁下一章节</template>
          <template v-else>本次答题情况：</template>
        </div>
        <div class="content">
          <div class="item">
            <div>
              <span style="font-size: 32px;">{{result.correctNum}}</span>
              <span>题</span>
            </div>
            <span>答对</span>
          </div>
          <div class="item center">
            <div>
              <span style="font-size: 32px;">{{result.incorrectNum}}</span>
              <span>题</span>
            </div>
            <span>答错</span>
          </div>
          <div class="item">
            <div>
              <span style="font-size: 32px;">{{(result.passRate * 100).toFixed(0)}}</span>
              <span style="font-size: 32px;">%</span>
            </div>
            <span>正确率</span>
          </div>
        </div>
				<div class="btn-inner">
					<div class="div">
						<a-button style="width: 113px;height: 36px;" @click="toSelfTest()">答题情况</a-button>
					</div>
					<div class="div" style="margin-left:56px;">
						<a-button style="width: 113px;height: 36px;" class="all-btn-small btn" v-if="result.isPass == 1" @click="goStudy()">继续学习</a-button>
						<a-button style="width: 113px;height: 36px;" class="all-btn-small btn" v-else @click="onReturn()">重新学习</a-button>
					</div>
				</div>
			</div>
    </a-modal>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      ABC: ['A','B','C','D','E','F','G','H','I','J','K'],
      list: [],
      submitPopup: false,
      showAnswer: false,
      result: {},
      disabled:false,
      loading:false,

      BooleArry:[], // 用于存放是否作答
      isNoOrder: 0,
      nextVodObj: {}, // 继续学习，下一节的播放源
    }
  },
  // 事件处理器
  methods: {
    // 解析
    funAnalysis(arry){
      let isAnswer = false
      let trueCount = 0  // 正确答案
      let selectCount = 0   // 所选答案
      let answerCount = 0  // 选对的数量
      arry.forEach(element => {
        if(element.isCorrect){ trueCount++ }
        if(element.value){ selectCount++ }
        if(element.isCorrect && element.value){ 
          answerCount++ 
        }
      });
      if((trueCount == answerCount) && (trueCount == selectCount)){
        isAnswer = true
      }
      return isAnswer
    },

    // 对错
    funIs(item){
      if(this.showAnswer){
        if(item.isCorrect == 1 && item.value){
          return 'success'
        }else if(item.isCorrect == 0 && item.value){
          return 'error'
        }else{
          return ''
        }
      }
    },

    // 查看答题情况
    toSelfTest(){
      this.showAnswer = true
      this.submitPopup = false
    },

    // 重新学习
    onReturn(){
      if(!this.isNoOrder){
        sessionStorage.setItem('selfTess', this.sectionId)
      }
      this.$router.go(-1)
    },

    // 继续学习
    goStudy(){
      if(!this.isNoOrder){
        sessionStorage.setItem('selfTess', this.sectionId)
        sessionStorage.setItem('continue', 1)
        this.$router.go(-1)
      } else {
        this.setVodCourse()
      }
    },

    // 选项修改
    changeOption(index,items,event){
      let item = JSON.parse(JSON.stringify(this.list[index]))
      if(item.type==1 || item.type==3){ // 单选或判断
        item.optionList.forEach(res=>{
          if(res.optionId == items.optionId){
            res.value = true
          }else{
            res.value = false
          }
        })
        this.$set(this.list,index,item)
      }else{
        item.optionList.forEach(res=>{
          if(res.optionId == items.optionId){
            res.value = event.target.checked
          }
        })
        this.$set(this.list,index,item)
      }
    },

    // 查询小节自测
    getExemList(){
      this.$ajax({
        url: '/hxclass-pc/library/section/test-list',
        params: {
          courseId: this.courseId,
          sectionId: this.sectionId
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.list = [
            ...res.data.radioList, // 单选题
            ...res.data.checkedList, // 多选题
            ...res.data.switchList // 判断题
          ]

          // 查询自测解析
          let arry = []
          this.list.forEach(item => {
            arry.push(item.libraryId)
          });
          this.getAnalysisList(arry)
        }
      })
    },

    // 查询题目解析
    getAnalysisList(arry){
      this.$ajax({
        url: '/hxclass-pc/library/getLibraryAnswer',
				method: 'post',
        params: {
          libraryIdList: arry,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          res.data.forEach(item => {
            this.list.forEach(child => {
              if(item.libraryId == child.libraryId){
                child.answer = item.answer
              }
            });
          });
        }
      })
    },

    // 提交小节自测
    onSave(){
      let msg = ''
			for(let i=0;i<this.list.length;i++){
				const item = this.list[i]
        let is = false
        for(let s=0;s<item.optionList.length;s++){
          if(item.optionList[s].value){
            is = true
          }
        }
        if(!is){
          msg = '请选择第'+(i+1)+'题'
					break
        }
			}
			if(msg != ''){
        this.$message.warning(msg);
				return false
			}

      let arr = new Array()
			for(let i=0;i<JSON.parse(JSON.stringify(this.list.length));i++){
				const item = JSON.parse(JSON.stringify(this.list[i]))
        for(let s=0;s<item.optionList.length;s++){
          if(item.optionList[s].value){
            item.optionList[s].value = 1
          }else{
            item.optionList[s].value = 0
          }
        }
        arr.push(item)
      }

      // 随机播放课程，正确率自算
      // if(this.isNoOrder){
      if(false){
        this.result = {
          correctNum:0,
          incorrectNum:0,
          passRate:0,
          isPass:1
        }
        this.BooleArry = []
        arr.forEach(uItem => {
          let rightArry = []  // 标准答案
          let studentArry = []  // 作答答案
          uItem.optionList.forEach((childItem,key) => {
            if(childItem.isCorrect){
              rightArry.push(key)
            }
            if(childItem.value){
              studentArry.push(key)
            }
          });
          this.funCorrect(rightArry,studentArry)
        });
        // 计算答对数、答错数、通过率
        this.BooleArry.forEach(element => {
          if(element){
            this.result.correctNum = this.result.correctNum + 1
          } else {
            this.result.incorrectNum = this.result.incorrectNum + 1
          }
        });
        this.result.passRate = this.result.correctNum / this.BooleArry.length
        this.submitPopup = true
        sessionStorage.setItem('result',JSON.stringify(this.result))
      } else {
        this.loading = true
        this.$ajax({
          url: '/hxclass-pc/course/change-pass-status',
          method: 'put',
          params: {
            courseId: this.courseId,
            sectionId: this.sectionId,
            userId: this.$store.state.userInfo.userId,
            libraryList: arr
          }
        }).then(res=>{
          this.loading = false
          if(res.code == 200 && res.success){
            this.result = res.data
            sessionStorage.setItem('result',JSON.stringify(res.data))
            this.submitPopup = true
          }else{
            this.$message.warning(res.message);
          }
        })
      }
    },

    // 播放下一节课程
    setVodCourse() {
      this.$ajax({
        url: "/hxclass-pc/course/getNextSectionVod",
        params: {
          courseId: this.courseId,
          sectionId: this.sectionId,
        },
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.nextVodObj = res.data
            sessionStorage.setItem('NextVod', JSON.stringify(this.nextVodObj))
            this.$router.go(-1)
          } else {
            this.$message.warning(res.message);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },

    funCorrect(a,b){
      // a 标准答案  b 学员答案
      //先判断两个数组长度要一致，如果不一致那不需要判断直接就不对了。
      if (a.length == b.length) {
        //every() 每一个都是true则返回true，如果有一个是false，那么直接返回false；
        let p = a.every((item, index) => {
          //判断的条件为a数组的每一项在b数组内查找不等于-1就代表有，有就为true，所有都是true就返回true
          this.BooleArry.push(b.indexOf(item) !== -1)
        });
      } else {
        //长度不一致也是错误的
        this.BooleArry.push(false)
      }
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.courseId = this.$AES.decode_data(this.$route.query.courseId)
    this.sectionId = this.$AES.decode_data(this.$route.query.sectionId)

    this.isNoOrder = this.$route.query.isNoOrder ? Number(this.$route.query.isNoOrder) : 0
    this.getExemList()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.crumbs{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  height: 68px;
  color: #666;
  a{
    color: #666;
  }
  .icon{
    margin: 0 5px;
  }
  .text{
    color: @theme;
  }
}
.answer-box{
  background-color: #fff;
  padding: 66px 76px;
  .title{
    color: #666666;
    font-size: 16px;
    line-height: 23px;
    font-weight: 400;
    margin-left: 20px;
  }
  .selectionTitle{
    margin-top: 8px;
    padding: 6px 20px;
    border: 1px solid #F3F3F3;
    border-radius: 19px;
    display: inline-block;
    font-size: 18px;
    line-height: 21px;
    color: #666666;
    margin-bottom: 22px;
    font-weight: 500;
    img{
      width: 16px;
      margin-right: 5px;
      position: relative;
      top: -2px;
    }
  }
  .questions{
    padding-bottom: 40px;
    .subject{
      display: flex;
      flex-direction: row;
      .type{
        flex: none;
        background-color: #FFC635;
        color: #fff;
        height: 23px;
        padding: 0 14px;
        border-radius: 11.5px;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 64px;
        margin-right: 16px;
      }
      .txt{
        font-size: 18px;
        line-height: 30px;
        white-space: pre-wrap;
      }
    }
    .option{
      display: flex;
      align-items: center;
      white-space:normal;
      padding-left: 80px;
      padding-top: 16px;
      // display: block;
      margin: 0;
      &.error{
        /deep/.ant-radio-disabled + span{
          color: #EA3C31;
        }
        /deep/.ant-checkbox-disabled + span{
          color: #EA3C31;
        }
      }
      &.success{
        /deep/.ant-radio-disabled + span{
          color: #52C41A;
        }
        /deep/.ant-checkbox-disabled + span{
          color: #52C41A;
        }
      }
      .txt{
        font-size: 16px;
        line-height: 18px;
        cursor: pointer;
      }
    }
    .answer-analysis{
      border-radius: 12px;
      margin-top: 24px;
      background: rgba(21, 183, 221, 0.05);
      padding: 24px;
      span{
        font-size: 18px;
        line-height: 27px;
        font-weight: 400;
        color: #333333;
        i{
          font-style: normal;
        }
        .iTrue{
          color: #15B7DD;
        }
        .iFalse{
          color: #FF0000;
        }
      }
      .txt{
        margin-top: 10px;
        font-size: 14px;
        line-height: 24px;
        i{
          color: @theme;
          font-style: normal;
        }
      }
    }
  }
  .operation{
    padding-top: 100px;
    button{
      width: 138px;
    }
  }
}
.popup-content {
  position: relative;
  .btn-inner {
    display: flex;
    justify-content: center;
    border-radius: 32px;

    .div {
      display: inline-block;
      border-radius: 32px;
      margin-top: 18px;
      background: #fff;
    }
  }
}
.btn{
  height: 32px;
  width: auto;
  line-height: 32px;
  border-radius: 4px;
}
/deep/.ant-modal-content{
  border-radius: 8px;
  position: relative;
  .popup-img{
    position: absolute;
  }
  .titleh4{
    font-size: 24px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 32px;
    text-align: center;
    padding-top: 34px;
    margin-bottom: 8px;
  }
  .tips{
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 32px;
  }
  .content{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 9px;
    .item{
      flex: 1;
      text-align: center;
      >div{
        font-size: 24px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
      }
      &.center{
        position: relative;
        &::before,&::after{
          content: '';
          width: 1px;
          height: 28px;
          background: #C4C4C4;
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }
        &::after{
          left: auto;
          right: 0;
        }
      }
      >span{
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
        display: block;
      }
    }
  }
}
</style>
